import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useProfileStore } from '../../store';
import RangePicker from 'react-range-picker';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../helpers/utils';

const ProfileModal = observer(() => {
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [sex, setSex] = useState('M');

  const navigate = useNavigate();

  const profileStore = useProfileStore();

  const onUserNameChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9]/gi, '');
    setUserName(value);
  };

  const onFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const onLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
  };

  const onMiddleNameChange = (e) => {
    const value = e.target.value;
    setMiddleName(value);
  };

  const onDateSelected = (date) => {
    if (date.getTime() >= new Date().getTime()) {
      toast('Дата рождения не может быть больше текущей даты', 'warn');
      return;
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const fullDate = `${year}-${month}-${day}`;

    setBirthDate(fullDate);
  };

  const onMaleSelect = () => {
    setSex('M');
  };

  const onFemaleSelect = () => {
    setSex('F');
  };

  const saveProfileInfo = async () => {
    if (!saveProfileCheck()) return;

    const data = {
      username: userName,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      sex,
      birth_date: birthDate,
    };

    const success = await profileStore.saveProfile(data);
    if (success) {
      navigate('/profile');
    }
  };

  const saveProfileCheck = () => {
    let allGood = true;

    if (userName.length <= 3) {
      toast('Имя пользователя должно быть больше 3 символов', 'warn');
      allGood = false;
    }

    if (birthDate == null) {
      toast('Установите дату рождения', 'warn');
      allGood = false;
    }

    return allGood;
  };

  return (
    <div className={styles.profileModal}>
      <div className={styles.window}>
        <p className={styles.title}>Сперва заполните свой профиль</p>
        <div className={styles.row}>
          <label htmlFor="userName">Короткое имя пользователя</label>
          <input
            type="text"
            name="userName"
            pattern="[A-Za-z0-9]"
            placeholder="мин 3 символа"
            value={userName}
            onChange={onUserNameChange}
            required
          />
          <span className={styles.required} title="обязательное поле">
            *
          </span>
        </div>
        <div className={styles.row}>
          <label htmlFor="firstName">Имя</label>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={onFirstNameChange}
          />
        </div>
        <div className={styles.row}>
          <label htmlFor="lastName">Фамилия</label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={onLastNameChange}
          />
        </div>
        <div className={styles.row}>
          <label htmlFor="lastName">Отчество</label>
          <input
            type="text"
            name="middleName"
            value={middleName}
            onChange={onMiddleNameChange}
          />
        </div>
        <div className={styles.row}>
          <label>Дата рождения</label>
          <div className={styles.birthPicker}>
            <RangePicker
              placeholderText="Выберете день своего рождения"
              disableRange
              closeOnSelect
              onDateSelected={onDateSelected}
              footer={() => null}
            />
          </div>
          <span className={styles.required} title="обязательное поле">
            *
          </span>
        </div>
        <div className={styles.row}>
          <label>Пол</label>
          <BsGenderFemale
            className={`${styles.female} ${sex === 'F' ? styles.selected : ''}`}
            title="женский"
            onClick={onFemaleSelect}
          />
          <BsGenderMale
            className={`${styles.male} ${sex === 'M' ? styles.selected : ''}`}
            title="мужской"
            onClick={onMaleSelect}
          />
          <span className={styles.sexText}>
            {sex === 'M' ? 'мужской' : 'женский'}
          </span>
        </div>
        <SimpleButton
          classes={styles.submitBtn}
          onClick={saveProfileInfo}
          text="Сохранить"
        />
      </div>
    </div>
  );
});

export default ProfileModal;
