import axios from 'axios';
import AuthStore from '../store/auth';
import { toast } from '../helpers/utils';
import { setCookie } from './cookie';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

// USERS
export const getUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEventParticipants = async (eventId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/event/${eventId}/participants/many/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyParticipants = async (relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/accepted/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyPendings = async (relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/pending/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const acceptFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/accept`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const cancelFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/cancel`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserFollowers = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/subscribers/many/accepted/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// PROFILES

export const getUserProfile = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/profiles/user/${userId}/one/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// EVENTS
export const getEventsByArea = async (area, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/many/area/get`,
      { ...area, relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const saveEventCover = async (eventId, cover) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/cover/save`,
      { cover: cover },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const participate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/participate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unparticipate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/unparticipate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserEvents = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/user/${userId}/many/ownership/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

//USER SUBSCRIPTIONS

export const getUserSubscriptionExists = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/exists`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscription = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      if (response.status === 204) return false;
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscribes = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/user/${userId}/meny/accepted/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMySubscribes = async (relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/many/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const subscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/create`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unSubscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/delete`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// VERIFICATION

export const sendEmailVerification = async () => {
  return axios
    .postForm(
      `${API_ENDPOINT}/verification/email/notify`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      toast('Письмо успешно отправлено на ваш email', 'success');
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const varificateEmail = async (url) => {
  return axios
    .post(url)
    .then((response) => {
      toast('Ваш email успешно подтвержден!', 'success');
      AuthStore.setVerified(true);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

const errorHandler = (data) => {
  toast(data.response.data.message, 'error');
};
