import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import styles from './styles.module.scss';
import { Link } from '@mui/material/';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

export default function Welcome() {
  const navigate = useNavigate();
  const [animationStart, setAnimationStart] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);

  const screenWidth = window.innerWidth;
  let fromZoom = 5;

  if (screenWidth <= 1280) {
    fromZoom = 4;

    if (screenWidth < 800) {
      fromZoom = 3;

      if (screenWidth < 500) {
        fromZoom = 2;
      }

      if (screenWidth < 400) {
        fromZoom = 1.5;
      }
    }
  }

  const spring = useSpring({
    delay: 1000,
    from: { zoom: fromZoom },
    to: { zoom: 1 },
    onStart: (e) => {
      onTitleAnimationStart();
    },
    onRest: (e) => {
      if (e.finished === true) onTitleAnimationFinish();
    },
    config: { friction: 50 },
  });

  const onTitleAnimationStart = () => {
    setAnimationStart(true);
  };

  const onTitleAnimationFinish = () => {
    setAnimationEnded(true);
  };

  return (
    <div className={styles.welcome}>
      <div className={styles.animatedBlock}>
        <animated.div className={styles.logo} style={{ ...spring }}>
          <div className={styles.icon} />
          <div className={styles.title}>Мероприятник</div>
        </animated.div>
        <p
          className={styles.description}
          style={{ opacity: animationStart ? 0 : 1 }}
        >
          лучший ивент сервис рунета
        </p>
      </div>

      <div
        className={styles.authBlock}
        style={{ opacity: animationEnded ? 1 : 0 }}
      >
        <SimpleButton onClick={() => navigate('/login')} text="Войти" />
        <Link href="/register">регистрация</Link>
      </div>
    </div>
  );
}
