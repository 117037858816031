import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import AuthStore from './auth';
import { toast } from '../helpers/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class EventsStore {
  myEvents = [];
  mySubscribeEvents = [];
  allEvents = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getMyEvents() {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/my/many/ownership/get`,
        {},
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setMyEvents(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getMySubscribeEvents() {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/my/many/participation/get`,
        {},
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setMySubscribeEvents(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getAllEvents(relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/many/get`,
        { relationships: [relationships] },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setAllEvents(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getEvent(eventId, relationships) {
    const event = this.allEvents.find(({ id }) => id === eventId);
    if (event) return event;

    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/one/get`,
        { relationships: relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async deleteEvent(eventId) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/delete`,
        {},
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        return this.removeEvent(eventId);
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  setMyEvents(data) {
    this.myEvents = data.list.filter((event) => event.deleted_at === null);
  }

  setMySubscribeEvents(data) {
    this.mySubscribeEvents = data.list.filter(
      (event) => event.deleted_at === null
    );
  }

  setAllEvents(data) {
    this.allEvents = data.list.filter((event) => event.deleted_at === null);
  }

  removeEvent(eventId) {
    this.myEvents = this.myEvents.filter((e) => e.id !== eventId);
    return this.myEvents;
  }

  async createEvent(data) {
    return axios
      .postForm(`${API_ENDPOINT}/events/new/save`, data, {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      })
      .then((response) => {
        this.addNewEvent(response.data);
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  addNewEvent(data) {
    this.myEvents.push(data);
  }

  errorHandler(data) {
    toast(data.response.data.message, 'error');
  }
}

const store = new EventsStore();

export default store;
