import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useEventsStore, useProfileStore } from '../../store/index';
import { useParams, useNavigate } from 'react-router-dom';

import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import {
  participate,
  unparticipate,
  getUserProfile,
  getEventParticipants,
} from '../../helpers/fetch';
import Avatar from '../../ui/components/avatar';
import { getUserLabel } from '../../helpers/utils';
import { BsFilePersonFill, BsSignTurnSlightRightFill } from 'react-icons/bs';
import UserListModal from '../../ui/components/usersListModal';
import Loader from '../../ui/components/loader';

const customMarker = new Icon({
  iconUrl: require('../../assets/imgs/map_marker_default.png'),
  iconSize: [38, 38],
});

const Event = observer(() => {
  const event = useRef(null);
  const creator = useRef(null);
  const myEvent = useRef(false);

  const [usersModal, setUsersModal] = useState(null);

  const [loading, setLoading] = useState(true);

  const [canParticipate, setCanParticipate] = useState(true);

  const [isParcipiant, setIsParcipiant] = useState(false);
  const [participants, setParticipants] = useState([]);

  const eventsStore = useEventsStore();
  const profileStore = useProfileStore();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const ageControlSuccess = useMemo(() => {
    if (event.current)
      return (
        event.current.participant_min_age <= profileStore.age &&
        profileStore.age <= event.current.participant_max_age
      );
    return true;
  }, [event.current]);

  const removeMapLogo = () => {
    const logo = document.querySelector('a[href="https://leafletjs.com"]');
    if (logo) {
      logo.nextElementSibling.remove();
      logo.remove();
    }
  };

  useEffect(() => {
    setCanParticipate(ageControlSuccess);
  }, [ageControlSuccess]);

  useEffect(() => {
    setLoading(true);

    async function fetchEvent() {
      event.current = await eventsStore.getEvent(parseInt(eventId), [
        'location',
        'cover.file',
      ]);
      if (!event.current) return;

      if (profileStore.id === event.current.author_id) myEvent.current = true;

      if (myEvent) {
        // TODO сделать чтоб можно было получить всех сразу, пока лимит 100
        const participants = await _getEventParticipants(event.current.id);
        if (participants) {
          setParticipants(participants.list);
          const myIndex = participants.list.findIndex(
            (p) => p.id === profileStore.id
          );
          setIsParcipiant(myIndex !== -1);
        }
      }

      creator.current = await getUserProfile(
        event.current.author_id,
        'avatar.file'
      );
      if (!creator.current) return;

      setLoading(false);
      removeMapLogo();
    }

    fetchEvent();
  }, []);

  const _getEventParticipants = async (eventId) => {
    return await getEventParticipants(eventId, 'profile.avatar.file');
  };

  const onParticipate = async () => {
    const response = await participate(event.current.id);
    if (response) {
      setIsParcipiant(true);
      const result = await _getEventParticipants(event.current.id);
      if (result) {
        setParticipants(result.list.map((p) => p.profile));
      }
    }
  };

  const onUnparticipate = async () => {
    const result = await unparticipate(event.current.id);
    if (result) {
      setIsParcipiant(false);
      const newParticipants = participants.filter(
        (p) => p.id !== profileStore.id
      );
      setParticipants(newParticipants);
    }
  };

  const showSubscribers = () => {
    setUsersModal({
      title: ['Участники мероприятия'],
      users: [participants],
      subscribes: false,
      acceptHandler: null,
      cancelHandler: null,
    });
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openUserProfile = (userId) => {
    if (userId === profileStore.id) navigate('/profile');
    else navigate(`/user/${userId}`);
  };

  if (loading) {
    return <Loader />;
  }

  let parcipiantCount = 'неограничено';
  if (
    event.current.participant_min_count !== null &&
    event.current.participant_max_count !== null
  ) {
    parcipiantCount = `от ${event.current.participant_min_count} до ${event.current.participant_max_count}`;
  }

  let parcipiantAge = 'любой возраст';
  if (
    event.current.participant_min_age !== null &&
    event.current.participant_max_age !== null
  ) {
    parcipiantAge = `от ${event.current.participant_min_age} до ${event.current.participant_max_age}`;
  }

  const startTime = moment(event.current.start_at);
  const endTime = moment(event.current.end_at);
  const nowTime = moment().valueOf();

  let timeLabel = '';
  let timeStyle = '';

  if (startTime.valueOf() < nowTime && nowTime < endTime.valueOf()) {
    timeLabel = 'сейчас идёт';
    timeStyle = styles.inTime;
  } else if (nowTime > endTime) {
    timeLabel = 'завершено';
    timeStyle = styles.outOfTime;
  }

  return (
    <>
      {usersModal && (
        <UserListModal
          owner={false}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      <div className={styles.event}>
        <div className={styles.information}>
          <div className={styles.name}>{event.current.name}</div>
          <div className={styles.creator}>
            {myEvent.current ? (
              <div
                className={styles.user}
                onClick={() => openUserProfile(creator.current.id)}
              >
                <span className={styles.userLabel}>
                  Вы являетесь организатором данного мероприятия
                </span>
              </div>
            ) : (
              <>
                <span>Организатор:</span>
                <div className={styles.user}>
                  <div className={styles.avatar}>
                    <Avatar
                      avatar={creator.current.avatar?.file.url}
                      placeholder={`#${creator.current.user_id}`}
                      size={24}
                    />
                  </div>
                  <span
                    className={styles.userLabel}
                    onClick={() => openUserProfile(creator.current.id)}
                  >
                    {getUserLabel(creator.current)}
                  </span>
                </div>
              </>
            )}
          </div>
          {event.current.cover?.file?.url && (
            <div className={styles.cover}>
              <img src={event.current.cover.file.url} />
            </div>
          )}
          <div className={styles.description}>
            Описание: {event.current.description}
          </div>
          <div
            className={`${styles.ages} ${
              ageControlSuccess ? '' : styles.error
            }`}
          >
            <span>Возрастные ограничения: {parcipiantAge}</span>
            {!ageControlSuccess && (
              <span> (ваш возраст {profileStore.age})</span>
            )}
          </div>
          <div className={styles.count}>
            <span>Количество участников: {parcipiantCount}</span>{' '}
            {myEvent.current && (
              <span
                className={styles.participants}
                onClick={showSubscribers}
                title="Показать участников"
              >
                <span className={styles.pcount}>{participants.length}</span>
                <BsFilePersonFill />
              </span>
            )}
          </div>
          <div className={styles.cost}>
            Стоимость участия:{' '}
            {event.current.cost === 0 ? (
              <span className={styles.green}>бесплатно</span>
            ) : (
              `${event.current.cost} руб.`
            )}
          </div>
          <div className={`${styles.timeBlock} ${timeStyle}`}>
            <div>
              Время проведения:
              <br />
              {timeLabel}
            </div>
            <div className={styles.time}>
              <div>{startTime.format('llll')}</div>
              <div>{endTime.format('llll')}</div>
            </div>
          </div>
          <div className={styles.address}>
            <div className={styles.title}>
              <span>Адрес: </span>
              {event.current.location.address}
            </div>
            <div className={styles.map}>
              <MapContainer
                center={[
                  event.current.location.coordinate_latitude,
                  event.current.location.coordinate_longitude,
                ]}
                zoom={17}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[
                    event.current.location.coordinate_latitude,
                    event.current.location.coordinate_longitude,
                  ]}
                  icon={customMarker}
                />
              </MapContainer>
              <a
                className={styles.navigateLink}
                href={`https://yandex.ru/maps/?rtext=~${event.current.location.coordinate_latitude}%2C${event.current.location.coordinate_longitude}`}
                target="_blank"
                title="Построить маршрут"
              >
                <BsSignTurnSlightRightFill /> Маршрут
              </a>
              {/* <img
              src={`https://static.maps.2gis.com/1.0?s=880x500&pt=${event.current.location.coordinate_latitude},${event.current.location.coordinate_longitude}&c=${event.current.location.coordinate_latitude},${event.current.location.coordinate_longitude}&z=17`}
            /> */}
            </div>
          </div>
          {!myEvent.current && (
            <div className={styles.privacy}>
              {event.current.privacy_type === 'PU'
                ? 'Это публичное мероприятие, участвовать может любой желающий.'
                : 'Это приватное мероприятие, подайте заявку и организатор выберет участников.'}
            </div>
          )}
        </div>
        {isParcipiant ? (
          <div className={styles.subscribeSection}>
            <span>Вы участвуете в мероприятии</span>
            <SimpleButton
              classes={styles.subscribeBtn}
              text="ОТКАЗАТЬСЯ"
              onClick={onUnparticipate}
            />
          </div>
        ) : (
          <div className={styles.subscribeSection}>
            <span>Вы не участвуете в мероприятии</span>
            <SimpleButton
              classes={styles.subscribeBtn}
              disabled={!canParticipate}
              text="УЧАСТВОВАТЬ"
              onClick={onParticipate}
            />
          </div>
        )}
      </div>
    </>
  );
});

export default Event;
