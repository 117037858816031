import React from 'react';
import { useNavigate } from 'react-router-dom';
import Settings from '../settings';
import styles from './styles.module.scss';

import {
  BsCalendarPlusFill,
  BsCalendar2WeekFill,
  BsFillPersonVcardFill,
} from 'react-icons/bs';

export default function Layout(props) {
  const { children, active } = props;
  const navigate = useNavigate();

  const createEvent = () => {
    navigate('/create');
  };

  const profile = () => {
    navigate('/');
  };

  const findEvent = () => {
    navigate('/events');
  };

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <div className={styles.icon} />
          <div className={styles.title}>Мероприятник</div>
        </div>
        <Settings />
      </div>
      <div className={styles.view}>{children}</div>
      <div className={styles.footer}>
        <div
          onClick={createEvent}
          className={`${styles.btn} ${
            active === 'create' ? styles.active : ''
          }`}
          title="Создать мероприятие"
        >
          <BsCalendarPlusFill className={styles.icon} />
          <p className={styles.title}>Создать</p>
        </div>
        <div
          onClick={profile}
          className={`${styles.btn} ${
            active === 'profile' ? styles.active : ''
          }`}
          title="Профиль"
        >
          <BsFillPersonVcardFill className={styles.icon} />
          <p className={styles.title}>Профиль</p>
        </div>
        <div
          onClick={findEvent}
          className={`${styles.btn} ${
            active === 'events' ? styles.active : ''
          }`}
          title="Просмотр мероприятий"
        >
          <BsCalendar2WeekFill className={styles.icon} />
          <p className={styles.title}>Мероприятия</p>
        </div>
      </div>
    </div>
  );
}
