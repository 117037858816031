import React, { useState } from 'react';

import { BsXCircleFill } from 'react-icons/bs';

import ImageUploading from 'react-images-uploading';
import Cropper from 'react-easy-crop';

import styles from './styles.module.scss';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material/';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return canvas.toDataURL('image/jpeg');
}

const cropImage = async (image, croppedAreaPixels, onError) => {
  try {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    return croppedImage;
  } catch (err) {
    onError(err);
  }
};

const CropperDialog = ({
  open,
  image,
  onComplete,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Обрезка изображения</DialogTitle>

      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={400 / 150}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
            cropSize={{ width: 400, height: 150 }}
            showGrid={false}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <SimpleButton
          onClick={() =>
            onComplete(cropImage(image, croppedAreaPixels, console.log))
          }
          text="Готово"
        />
      </DialogActions>
    </Dialog>
  );
};

export default function ImageCropper(props) {
  const { callback } = props;
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const completeCropped = (image) => {
    setCroppedImage(image);
    setDialogOpen(false);
    callback(image);
  };

  const removeImage = () => {
    setImage([]);
    setCroppedImage(null);
    callback(null);
  };

  const onImageUploadChange = (newImage) => {
    setCroppedImage(newImage);
    setDialogOpen(true);
    setImage(newImage);
  };

  return (
    <div
      className={styles.imageCropper}
      style={{ backgroundImage: `url(${croppedImage})` }}
    >
      {croppedImage && (
        <BsXCircleFill
          size={22}
          className={styles.removeBtn}
          onClick={removeImage}
        />
      )}
      <ImageUploading value={image} onChange={onImageUploadChange}>
        {({ onImageUpload, onImageUpdate }) => (
          <div
            className={`${styles.loadBtn} ${croppedImage ? styles.reload : ''}`}
            onClick={image ? onImageUpload : () => onImageUpdate(0)}
          >
            Выберите обложку
          </div>
        )}
      </ImageUploading>
      <CropperDialog
        open={dialogOpen}
        image={image.length > 0 && image[0].dataURL}
        onComplete={(imagePromisse) => {
          imagePromisse.then((image) => {
            completeCropped(image);
          });
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: 300,
          background: '#333',
        }}
      />
    </div>
  );
}
