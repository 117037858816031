import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import AuthStore from './auth';
import moment from 'moment';
import { toast } from '../helpers/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class ProfileStore {
  id = '';
  username = '';
  firstName = '';
  lastName = '';
  middleName = '';
  birthDate = '';
  age = 0;
  sex = '';
  email = '';
  avatar = '';

  constructor() {
    makeAutoObservable(this);

    this.checkStored();
  }

  checkStored() {
    const profile = localStorage.getItem('profile');
    if (profile) {
      const profileObj = JSON.parse(profile);
      this.id = profileObj.id;
      this.username = profileObj.username;
      this.firstName = profileObj.firstName;
      this.lastName = profileObj.lastName;
      this.middleName = profileObj.middleName;
      this.birthDate = profileObj.birthDate;
      this.age = profileObj.age;
    }
  }

  async getProfile(relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/profiles/my/one/get`,
        { relationships: [relationships] },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          return false;
        }
        this.setProfileData(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  setProfileData(data) {
    this.id = data.id;
    this.username = data.username;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.middleName = data.middle_name;
    this.birthDate = data.birth_date;
    this.sex = data.sex;
    this.avatar = data.avatar?.file.url || '';
    this.email = '';
    this.age = moment().diff(moment(data.birth_date, 'YYYYMMDD'), 'years');

    localStorage.setItem('profile', JSON.stringify({ ...data, age: this.age }));
  }

  errorHandler(data) {
    toast(data.response.data.message, 'error');
  }

  async saveProfile(data) {
    return axios
      .postForm(
        `${API_ENDPOINT}/profiles/my/save`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setProfileData(response.data);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async saveAvatar(avatar) {
    const formData = new FormData();
    formData.append('avatar', avatar, 'avatar.png');
    formData.append('relationships[]', 'file');
    return axios
      .post(`${API_ENDPOINT}/profiles/my/avatar/save`, formData, {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      })
      .then((response) => {
        this.setProfileAvatar(response.data.file.url);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  setProfileAvatar(avatar) {
    this.avatar = avatar;
  }
}

const store = new ProfileStore();

export default store;
