import Avvvatars from 'avvvatars-react';
import styles from './styles.module.scss';

const Avatar = (props) => {
  const { avatar, size, placeholder } = props;

  return avatar ? (
    <div
      className={styles.avatar}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${avatar})`,
      }}
    />
  ) : (
    <Avvvatars
      className={styles.avatar}
      size={size}
      style="shape"
      border={true}
      borderSize={1}
      borderColor="#2a4480"
      value={placeholder}
    />
  );
};

export default Avatar;
