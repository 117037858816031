import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useProfileStore, useEventsStore } from '../../store/index';
import { useNavigate } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import styles from './styles.module.scss';
import ProfileModal from '../../components/profileModal';

import { BsFillPlusCircleFill, BsFiles, BsFilesAlt } from 'react-icons/bs';
import ImageUpload from '../../components/imageUpload';
import Avatar from '../../ui/components/avatar';
import EventCard from '../../ui/components/eventCard';
import {
  acceptFollower,
  cancelFollower,
  getMyParticipants,
  getMyPendings,
  getMySubscribes,
  unSubscribeUser,
} from '../../helpers/fetch';
import UserListModal from '../../ui/components/usersListModal';
import AlertDialog from '../../ui/components/dialog';
import Loader from '../../ui/components/loader';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

const Profile = observer(() => {
  const profileStore = useProfileStore();
  const eventsStore = useEventsStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [eventsTab, setEventsTab] = useState(0);
  const [events, setEvents] = useState([]);
  const [pendings, setPendings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [subscribes, setSubscribes] = useState([]);

  const [profileModal, setProfileModal] = useState(false);
  const [avatarUpload, setAvatarUpload] = useState(false);

  const [usersModal, setUsersModal] = useState(null);
  const [promptDialog, setPromptDialog] = useState(null);

  useEffect(() => {
    setLoading(true);

    async function fetchProfile() {
      const profile = await profileStore.getProfile('avatar.file');
      if (!profile) {
        setProfileModal(true);
      }

      const events = await eventsStore.getMyEvents();
      if (!events) return;

      setEvents(eventsStore.myEvents);
      const subscribeEvents = await eventsStore.getMySubscribeEvents();
      if (!subscribeEvents) return;

      const pendings = await getMyPendings('profile.avatar.file');
      if (!pendings) return;
      setPendings(pendings.list);

      const followers = await getMyParticipants('profile.avatar.file');
      if (!followers) return;
      setFollowers(followers.list);

      const subscribes = await getMySubscribes('user.profile.avatar.file');
      if (!subscribes) return;
      setSubscribes(subscribes.list);

      setLoading(false);
    }

    fetchProfile();
  }, []);

  const openAvatarUploader = () => {
    setAvatarUpload(true);
  };

  const onAvatarUploaderClose = async (imgBase64) => {
    setAvatarUpload(false);

    if (imgBase64) {
      const image = new Image();

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.setAttribute('width', 100);
        canvas.setAttribute('height', 100);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, 100, 100);
        const dataUrl = canvas.toDataURL('image/png');

        fetch(dataUrl)
          .then((res) => res.blob())
          .then(async (res) => {
            const success = await profileStore.saveAvatar(res);
          });
      };

      image.src = imgBase64;
    }
  };

  const onChangeEventTab = (event, newValue) => {
    setEventsTab(newValue);
    switch (newValue) {
      case 0:
        setEvents(eventsStore.myEvents);
        break;
      case 1:
        setEvents(eventsStore.mySubscribeEvents);
        break;
    }
  };
  const showFollowers = (followers, pendings) => {
    setUsersModal({
      title: ['Мои подписчики', 'Заявки'],
      users: [followers, pendings],
      subscribes: false,
      acceptHandler: _acceptFollower,
      cancelHandler: cancelFollowerPrompt,
    });
  };

  const showSubscribes = (subscribes) => {
    const acceptedSubscribes = subscribes
      .filter((s) => s.accepted_at !== null)
      .map((s) => s.user);
    const pendingSubscribes = subscribes
      .filter((s) => s.accepted_at == null)
      .map((s) => s.user);

    setUsersModal({
      title: ['Подписки', 'На рассмотрении'],
      users: [acceptedSubscribes, pendingSubscribes],
      subscribes: true,
      acceptHandler: null,
      cancelHandler: cancelSubscribesPropmt,
    });
  };

  const closeUsersModal = () => {
    setUsersModal(null);
  };

  const openEvent = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const openUserProfile = (userId) => {
    navigate(`/user/${userId}`);
  };

  const _acceptFollower = async (userId) => {
    const success = await acceptFollower(userId);

    if (success) {
      const newFollower = pendings.find((f) => f.id == userId);
      const newFollowers = [...followers, newFollower];
      setFollowers(newFollowers);

      const newPendings = pendings.filter((p) => p.id !== userId);
      setPendings(newPendings);

      showFollowers(newFollowers, newPendings);
    }
  };

  const cancelFollowerPrompt = (userId) => {
    setPromptDialog({
      title: 'Удалить подписчика',
      description: 'Точно хотите удалить подписчика?',
      onAccept: () => _cancelFollower(userId),
      onCancel: () => closePromptDialog(),
    });
  };

  const _cancelFollower = async (userId) => {
    setLoading(true);
    const success = await cancelFollower(userId);
    setLoading(false);

    if (success) {
      const newPendings = pendings.filter((p) => p.id !== userId);
      setPendings(newPendings);

      const newFollowers = followers.filter((f) => f.id !== userId);
      setFollowers(newFollowers);

      showFollowers(newFollowers, newPendings);
    }
  };

  const cancelSubscribesPropmt = (userId) => {
    setPromptDialog({
      title: 'Отписаться',
      description: 'Точно хотите удалить отписаться?',
      onAccept: () => _cancelSubscribes(userId),
      onCancel: () => closePromptDialog(),
    });
  };

  const _cancelSubscribes = async (userId) => {
    const success = await unSubscribeUser(userId);

    if (success) {
      const newSubscribes = subscribes.filter((s) => s.id !== userId);
      setSubscribes(newSubscribes);
      showSubscribes(newSubscribes);
    }
  };

  const onEventDeletePrompt = (eventId) => {
    setPromptDialog({
      title: 'Удаление мероприятия',
      description: 'Точно хотите удалить мероприятие?',
      onAccept: () => deleteEvent(eventId),
      onCancel: () => closePromptDialog(),
    });
  };

  const closePromptDialog = () => {
    setPromptDialog(null);
  };

  const deleteEvent = async (eventId) => {
    setLoading(true);
    const result = await eventsStore.deleteEvent(eventId);
    setLoading(false);
    setPromptDialog(null);

    if (result) {
      setEvents(result);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (profileModal) {
    return <ProfileModal />;
  }

  return (
    <>
      {usersModal && (
        <UserListModal
          owner={true}
          {...usersModal}
          opened={usersModal !== null}
          openUserProfile={openUserProfile}
          onClose={closeUsersModal}
        />
      )}
      {promptDialog && (
        <AlertDialog
          title={promptDialog.title}
          description={promptDialog.description}
          onAccept={promptDialog.onAccept}
          onCancel={promptDialog.onCancel}
        />
      )}
      <div className={styles.profile}>
        {avatarUpload && (
          <ImageUpload callback={onAvatarUploaderClose} label="Выберите фото" />
        )}
        <div className={styles.infoBlock}>
          <div
            className={styles.followers}
            onClick={() => showFollowers(followers, pendings)}
          >
            ПОДПИСЧИКИ
            <div className={styles.count}>{followers.length}</div>
            {pendings.length > 0 && (
              <div className={styles.new}>+{pendings.length}</div>
            )}
          </div>
          <div className={styles.mainInfo}>
            <p className={styles.username}>{profileStore.username}</p>
            <div className={styles.avatarBlock}>
              <Avatar
                avatar={profileStore.avatar}
                placeholder={`#${profileStore.id}`}
                size={100}
              />
              <div
                onClick={openAvatarUploader}
                className={styles.changeAvatarBtn}
              >
                <BsFillPlusCircleFill size={25} />
                <div className={styles.plusBg} />
              </div>
            </div>
            <p className={styles.fio}>
              {profileStore.lastName} {profileStore.firstName}{' '}
              {profileStore.middleName}
            </p>
          </div>
          <div
            className={styles.subscribes}
            onClick={() => showSubscribes(subscribes)}
          >
            ПОДПИСКИ
            <div className={styles.count}>{subscribes.length}</div>
          </div>
        </div>
        <div className={styles.events}>
          <Tabs value={eventsTab} onChange={onChangeEventTab} centered>
            <Tab icon={<BsFiles />} label="ОРГАНИЗАТОР" />
            <Tab icon={<BsFilesAlt />} label="УЧАСТВУЮ" />
          </Tabs>
          <div className={styles.eventsList}>
            {events.length == 0 ? (
              eventsTab == 0 ? (
                <div className={styles.warning}>
                  У вас ещё нет ни одного мероприятия
                  <br />
                  <SimpleButton
                    text="создать"
                    onClick={() => navigate('/create')}
                  />
                </div>
              ) : (
                <div className={styles.warning}>
                  Вы не участвуете ещё ни в одном мероприятии
                  <br />
                  <SimpleButton
                    text="найти мероприятие"
                    onClick={() => navigate('/events')}
                  />
                </div>
              )
            ) : (
              events.map((event) => (
                <EventCard
                  key={event.id}
                  owner={event.author_id == profileStore.id}
                  event={event}
                  onDelete={onEventDeletePrompt}
                  onClick={openEvent}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Profile;
