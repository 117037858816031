import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { useAuthStore } from '../../store/index';
import { TextField } from '@mui/material/';
import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { sendEmailVerification, varificateEmail } from '../../helpers/fetch';
import Loader from '../../ui/components/loader';
import { toast } from '../../helpers/utils';

const Verification = observer(() => {
  const [showVerBtn, setShowVerBtn] = useState(true);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [alreadySend, setAlreadySend] = useState(false);
  const [searchParams] = useSearchParams();
  const authStore = useAuthStore();
  const [loading, setLoading] = useState(false);

  let encodedUrl = '';

  if (searchParams.has('encoded')) {
    encodedUrl = searchParams.get('encoded');
  }

  useEffect(() => {
    if (encodedUrl == '') setShowVerBtn(false);
  }, []);

  const onEmailInput = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
  };

  const onPassInput = (e) => {
    const newValue = e.target.value;
    setPass(newValue);
  };

  const onLoginClick = async () => {
    if (pass.length < 8) {
      toast(
        'Количество символов пароля должно быть больше или равно 8',
        'warn'
      );
      return;
    }
    if (email.length == 0) {
      toast('email не должен быть пустым', 'warn');
      return;
    }

    setLoading(true);
    const success = await authStore.login(email, pass);
    setLoading(false);
  };

  const onLogoutClick = async () => {
    setLoading(true);
    await authStore.logout();
    setLoading(false);
  };

  const _varificateEmail = async () => {
    setLoading(true);
    const decodedURL = window.atob(decodeURIComponent(encodedUrl));
    await varificateEmail(decodedURL);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.verification}>
      <div className={styles.title}>
        <span className={styles.logo} />
        <span className={styles.text}>
          Подтверждение адреса электронной почты
        </span>
      </div>
      {authStore.accessToken ? (
        <>
          {showVerBtn && (
            <SimpleButton
              classes={styles.btn}
              text="Подтвердить email"
              onClick={_varificateEmail}
            />
          )}
          <SimpleButton
            classes={styles.btn}
            disabled={alreadySend}
            text="Отправить новое письмо"
            onClick={() => {
              setAlreadySend(true);
              sendEmailVerification();
              setShowVerBtn(false);
            }}
          />
          <SimpleButton
            classes={styles.btn}
            onClick={onLogoutClick}
            text="Разлогиниться"
          />
        </>
      ) : (
        <>
          <div className={styles.text}>
            Для верификации email необходимо быть авторизованным
          </div>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            required
            value={email}
            onInput={onEmailInput}
          />
          <TextField
            label="Пароль"
            variant="outlined"
            type="password"
            required
            value={pass}
            onInput={onPassInput}
          />
          <SimpleButton onClick={onLoginClick} text="Войти" />
        </>
      )}
    </div>
  );
});

export default Verification;
