import React from 'react';
import Button from '@mui/material/Button';
import styles from './SimpleButton.module.scss';

const SimpleButton = (props) => {
  const { onClick, text, disabled, classes } = props;

  return (
    <Button
      className={`${classes || ''} ${styles.simpleButton}`}
      onClick={onClick}
      variant="contained"
      disabled={disabled || false}
    >
      {text}
    </Button>
  );
};

export default SimpleButton;
