import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import { BsXCircleFill } from 'react-icons/bs';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';

import styles from './styles.module.scss';

export default function ImageUpload(props) {
  const { callback, label } = props;
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState('');

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  return (
    <div className={styles.imageUploaderWrapper}>
      <BsXCircleFill
        className={styles.close}
        size="20px"
        onClick={() => callback(null)}
      />
      <Avatar
        width={390}
        height={295}
        imageHeight={295}
        onCrop={onCrop}
        onClose={onClose}
        src={src}
        label={label}
        labelStyle={{
          display: 'block',
          color: 'white',
          fontFamily: 'LineBd',
          fontSize: '1.5rem',
          cursor: 'pointer',
        }}
      />
      {preview && (
        <SimpleButton
          classes={styles.submit}
          text="Загрузить"
          onClick={() => callback(preview)}
        />
      )}
    </div>
  );
}
