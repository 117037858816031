import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useEventsStore } from '../../store/index';
import { useForm } from 'react-hook-form';
import {
  BsFillLockFill,
  BsFillUnlockFill,
  BsPinMapFill,
  BsFillGeoFill,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import RangePicker from 'react-range-picker';
import moment from 'moment';

import { MapContainer, TileLayer } from 'react-leaflet';

import {
  TextField,
  Switch,
  Slider,
  InputAdornment,
  Input,
} from '@mui/material';

import styles from './styles.module.scss';
import SimpleButton from '../../ui/buttons/SimpleButton/SimpleButton';
import { toast } from '../../helpers/utils';
import Loader from '../../ui/components/loader';
import ImageCropper from '../../components/imageCropper';
import { saveEventCover } from '../../helpers/fetch';

const CreateEvent = observer(() => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const eventsStore = useEventsStore();
  const [cover, setCover] = useState('');
  const [privateChecked, setPrivateChecked] = useState(false);
  const [ageLimits, setAgeLimits] = useState({ min: 25, max: 75 });
  const [hasAgeLimits, setHasAgeLimits] = useState(false);
  const [peopleLimits, setPeopleLimits] = useState({ min: 1, max: 50 });
  const [hasPeopleLimits, setHasPeopleLimits] = useState(false);
  const [coords, setCoords] = useState([0, 0]);
  const [eventData, setData] = useState({ start: '', end: '' });
  const { ref, ...rest } = register('event-private');
  const privateRef = useRef(null);
  const [mapOpened, setMapOpened] = useState(false);
  const mapRef = useRef(null);
  const [myLocation, setMyLocation] = useState({ latitude: 0, longitude: 0 });

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        successLocationHandler,
        errorLocationHandler
      );
    } else {
      alert('geolocation IS NOT available');
    }
  };

  const successLocationHandler = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setMyLocation({
      latitude,
      longitude,
    });

    mapRef.current.setView([latitude, longitude], 15);
  };

  const errorLocationHandler = (error) => {
    if (mapRef.current) {
      mapRef.current.setView([0, 0], 1);
    }

    switch (error.code) {
      case error.PERMISSION_DENIED:
        toast(
          'Невозможно узнать ваше местоположение, необходимо дать доступ.',
          'error'
        );
        break;
      case error.POSITION_UNAVAILABLE:
        toast('Невозможно узнать ваше местоположение.', 'error');
        break;
      case error.TIMEOUT:
        toast('Вышло время ответа о получении вашего местоположения.', 'error');
        break;
      case error.UNKNOWN_ERROR:
        toast('Неизвестная ошибка о получении вашего местоположения.', 'error');
        break;
    }
  };

  const onLockClick = () => {
    privateRef.current.click();
  };

  const onPrivateChange = (checked) => {
    setPrivateChecked(checked);
  };

  const onAgeSliderInput = (event, newValue) => {
    setAgeLimits({ min: newValue[0], max: newValue[1] });
  };

  const onPeopleLimitsSliderInput = (event, newValue) => {
    setPeopleLimits({ min: newValue[0], max: newValue[1] });
  };

  const onMinAgeInput = (e) => {
    const value = parseInt(e.target.value);
    setAgeLimits({
      min: value,
      max: ageLimits.max,
    });
  };

  const onMaxAgeInput = (e) => {
    const value = parseInt(e.target.value);
    setAgeLimits({
      min: ageLimits.min,
      max: value,
    });
  };

  const onSwitchAgeLimits = (e) => {
    const checked = e.target.checked;
    setHasAgeLimits(checked);
  };

  const onMinPeopleInput = (e) => {
    const value = parseInt(e.target.value);
    setPeopleLimits({
      min: value,
      max: peopleLimits.max,
    });
  };

  const onMaxPeopleInput = (e) => {
    const value = parseInt(e.target.value);
    setPeopleLimits({
      min: peopleLimits.min,
      max: value,
    });
  };

  const onSwitchPeopleLimits = (e) => {
    const checked = e.target.checked;
    setHasPeopleLimits(checked);
  };

  const onCoodrsHandler = (lat, long) => {
    setCoords([lat, long]);
  };

  const onMapMove = () => {
    const coords = mapRef.current.getCenter();
    setCoords([coords.lat, coords.lng]);
  };

  const openMap = () => {
    setMapOpened(true);
    getCurrentLocation();

    setTimeout(() => {
      mapRef.current.on('move', onMapMove);

      const logo = document.querySelector('a[href="https://leafletjs.com"]');
      if (logo) {
        logo.nextElementSibling.remove();
        logo.remove();
      }
    }, 0);
  };

  const closeMap = () => {
    setMapOpened(false);
  };

  const onDateSelected = (f, l) => {
    setData({
      start: f,
      end: l,
    });
  };

  const onSubmit = async (data) => {
    const event = {
      name: data['event-name'],
      description: data['event-info'],
      privacy_type: privateChecked ? 'PR' : 'PU',
      participant_min_age: hasAgeLimits ? ageLimits.min : '',
      participant_max_age: hasAgeLimits ? ageLimits.max : '',
      participant_min_count: hasPeopleLimits ? peopleLimits.min : '',
      participant_max_count: hasPeopleLimits ? peopleLimits.max : '',
      cost: parseInt(data['event-cost']),
      start_at: moment(eventData.start).utc().format(),
      end_at: moment(eventData.end).utc().format(),
      address: data['event-address'],
      coordinate_latitude: coords[0],
      coordinate_longitude: coords[1],
    };

    setLoading(true);
    const result = await eventsStore.createEvent(event);

    if (result) {
      if (cover) {
        const image = new Image();

        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.setAttribute('width', 400);
          canvas.setAttribute('height', 150);
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, 400, 150);
          const dataUrl = canvas.toDataURL('image/jpeg');

          fetch(dataUrl)
            .then((res) => res.blob())
            .then(async (res) => {
              const coverResult = await saveEventCover(result.id, res);
              setLoading(false);
              navigate(`/event/${result.id}`);
            });
        };

        image.src = cover;
      } else {
        setLoading(false);
        navigate(`/event/${result.id}`);
      }
    } else {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.createevent}>
      {mapOpened && (
        <div className={styles.mapContainer}>
          <div className={styles.map}>
            <BsFillGeoFill className={styles.center} size={32} />
            <div className={styles.coords}>
              <span>{coords[0]}</span>
              <span>{coords[1]}</span>
            </div>
            <SimpleButton
              classes={styles.closeMap}
              text="Выбрать"
              onClick={closeMap}
            />
            <MapContainer
              ref={mapRef}
              center={[myLocation.latitude, myLocation.longitude]}
              zoom={15}
              scrollWheelZoom={true}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </div>
        </div>
      )}
      <div className={styles.window}>
        <p className={styles.title}>Создание нового мероприятия</p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formRow}>
            <TextField
              label="Название"
              variant="filled"
              fullWidth
              required
              error={errors['event-name']?.type === 'required'}
              inputProps={{
                minLength: 5,
                maxLength: 50,
              }}
              helperText={
                errors['event-name']?.type === 'required'
                  ? 'поле обязательно (мин. 5, макс. 50 символов)'
                  : 'мин. 5, макс. 200 символов'
              }
              {...register('event-name', {
                required: true,
                minLength: 5,
                maxLength: 50,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <ImageCropper callback={(image) => setCover(image)} />
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Описание"
              variant="filled"
              fullWidth
              required
              multiline
              error={errors['event-info']?.type === 'required'}
              inputProps={{
                minLength: 10,
                maxLength: 200,
              }}
              helperText={
                errors['event-info']?.type === 'required'
                  ? 'поле обязательно (мин. 10, макс. 200 символов)'
                  : 'мин. 10, макс. 200 символов'
              }
              {...register('event-info', {
                required: true,
                minLength: 10,
                maxLength: 200,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Стоимость участия"
              fullWidth
              defaultValue={0}
              inputProps={{
                type: 'number',
                min: 0,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">руб.</InputAdornment>
                ),
              }}
              {...register('event-cost')}
              variant="filled"
            />
          </div>
          <div className={styles.formRow}>
            <label htmlFor="private" className={styles.label}>
              Приватность
            </label>
            <input
              className={styles.hiddenCheckbox}
              type="checkbox"
              name="private"
              {...register('event-private')}
              ref={(e) => {
                ref(e);
                privateRef.current = e;
              }}
              onChange={(e) => {
                onPrivateChange(e.target.checked);
              }}
            />
            {privateChecked ? (
              <BsFillLockFill onClick={onLockClick} className={styles.lock} />
            ) : (
              <BsFillUnlockFill onClick={onLockClick} className={styles.lock} />
            )}
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Возрастная группа</label>
            <div className={styles.ageRangeBlock}>
              <Input
                value={ageLimits.min}
                size="small"
                onChange={onMinAgeInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 99,
                  type: 'number',
                }}
                disabled={!hasAgeLimits}
              />
              <Slider
                className={styles.range}
                value={[ageLimits.min, ageLimits.max]}
                onChange={onAgeSliderInput}
                disabled={!hasAgeLimits}
                min={1}
                max={99}
              />
              <Input
                value={ageLimits.max}
                size="small"
                onChange={onMaxAgeInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 99,
                  type: 'number',
                }}
                disabled={!hasAgeLimits}
              />
              <div>
                <Switch checked={hasAgeLimits} onChange={onSwitchAgeLimits} />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Количество участников</label>
            <div className={styles.peopleRangeBlock}>
              <Input
                value={peopleLimits.min}
                size="small"
                onChange={onMinPeopleInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999,
                  type: 'number',
                }}
                disabled={!hasPeopleLimits}
              />
              <Slider
                className={styles.range}
                value={[peopleLimits.min, peopleLimits.max]}
                onChange={onPeopleLimitsSliderInput}
                disabled={!hasPeopleLimits}
                min={1}
                max={999}
              />
              <Input
                value={peopleLimits.max}
                size="small"
                onChange={onMaxPeopleInput}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 999,
                  type: 'number',
                }}
                disabled={!hasPeopleLimits}
              />
              <div>
                <Switch
                  checked={hasPeopleLimits}
                  onChange={onSwitchPeopleLimits}
                />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <TextField
              label="Адрес"
              variant="filled"
              fullWidth
              required
              error={errors['event-address']?.type === 'required'}
              inputProps={{
                minLength: 10,
                maxLength: 250,
              }}
              helperText={
                errors['event-adress']?.type === 'required'
                  ? 'поле обязательно (мин. 10, макс. 250 символов)'
                  : 'мин. 10, макс. 250 символов'
              }
              {...register('event-address', {
                required: true,
                minLength: 10,
                maxLength: 250,
              })}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Координаты на карте *</label>
            <BsPinMapFill
              className={styles.mapBtn}
              size={24}
              onClick={openMap}
            />
            <div className={styles.coords}>
              <div className={styles.coord}>
                <span>N</span>
                <input
                  value={coords[0]}
                  onKeyPress={(event) => {
                    if (!/[0-9]|\./.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="широта"
                  onChange={(e) => onCoodrsHandler(e.target.value, coords[1])}
                />
              </div>
              <div className={styles.coord}>
                <span>E</span>
                <input
                  value={coords[1]}
                  onKeyPress={(event) => {
                    if (!/[0-9]|\./.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="долгота"
                  onChange={(e) => onCoodrsHandler(coords[0], e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Дата</label>
            <RangePicker selectTime onDateSelected={onDateSelected} />
          </div>
          <SimpleButton
            type="submit"
            text="Создать"
            classes={styles.createBtn}
            onClick={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </div>
  );
});

export default CreateEvent;
